<template>
    <div class="bg">
        <sideBar :pageName="pagename" activeSection="202211"></sideBar>
        <div class="content">
            <div class="title">
              全球首都大挑戰 伊將陪你遊全球
            </div>
            <div class="subTitle">活動數據</div>
            <div class="inputGroup">
                <date-picker v-model="time" valueType="YYYY-MM-DD" range></date-picker>
                <button class="searchBtn" @click="setupData">查詢</button>
                <button class="clearBtn" @click="clear">清除</button>
            </div>
            <p style="margin-bottom: 0px; font-weight: bold;font-size: 24px;color: #6d6c6c">活動人數總覽</p>
            <div class="dataList">
                <div class="row">
                    <div class="col-4" v-for="(item, index) in dataGroup" :key="index">
                        <div class="block" v-if="[0, 1, 2].indexOf(index) >= 0">
                            <p>{{ item.label }}
                                <span class="tooltiptext">{{ hints[index] }}</span></p>
                            <div class="count">{{ item.value }}</div>
                            <div class="unit">人</div>
                        </div>
                    </div>
                </div>
            </div>
            <p style="margin-bottom: 0px; font-weight: bold;font-size: 24px;color: #6d6c6c">各任務人數總覽</p>
            <div class="dataList">
                <div class="row">
                    <div class="col-4" v-for="(item, index) in dataGroup" :key="index">
                        <div class="block" v-if="[3, 4, 5].indexOf(index) >= 0">
                            <p>{{ item.label }}
                              <span class="tooltiptext">{{ hints[index] }}</span></p>
                            <div class="count">{{ item.value }}</div>
                            <div class="unit">人</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import sideBar from '../../components/side-bar'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import adminApi from "@/apis/adminV3";

export default {
    name: 'dashboard',
    components: {
        sideBar,
        DatePicker
    },
    data() {
        return {
            pagename: '202211活動數據',
            time: null,
            dataGroup: [
                {
                    label: '進入遊戲頁人數',
                    value: 0,
                },
                {
                    label: '完成兩個任務人數',
                    value: 0,
                },
                {
                    label: '成功邀請好友總數',
                    value: 0,
                },
                {
                  label: '完成ＱＡ問答人數',
                  value: 0,
                },
                {
                  label: '保戶簽到人數',
                  value: 0,
                },
            ],
            hints:[
              '從LINE OA開啟遊戲頁',
              '完成首都挑戰遊戲抽獎＋至少寄出一張明信片＋完成保戶簽到的人數',
              '成功邀請好友數的總和',
              '完成首都挑戰＋一次抽獎',
              '至少寄出一張明信片人數',
              '完成保戶簽到(之前完成簽到者，仍需點選活動頁上的簽到按鈕，才能獲得遊戲機會)'
            ]
        }
    },
    created() {
        this.setupData()
    },
    methods: {
        setupData() {
            let data = {}
            if (this.time) {
                data = {
                  start_date: this.time[0],
                  end_date: this.time[1]
                }
            }
            adminApi.overview(data).then((json) => {
                console.log(json.data.statisticsData)
                this.dataGroup = json.data.statisticsData
            });
        },
        clear() {
            this.time = null
        }
    }
}
</script>

<style lang="scss" scoped>
.bg {
    min-height: 100vh;
    width: 100vw;
    display: inline-flex;
}

.content {
    margin-top: 55px;
    margin-left: 40px;

    .title {
        border-left: 10px solid #5386c0;
        padding-left: 20px;
        font-size: 36px;
        font-weight: bold;
    }

    .subTitle {
        margin-top: 20px;
        font-size: 24px;
        font-width: 500;
        color: #6d6c6c;
    }

    .inputGroup {
        margin-top: 20px;
        display: inline-flex;

        button {
            margin-left: 10px;
            color: #fff;
            width: auto;
            min-width: 80px;
            border-radius: 5px;
            cursor: pointer;
        }

        .searchBtn {
            background-color: #5386c0;
        }

        .clearBtn {
            background-color: #b1b1b1;
        }
    }

    .dataList {
        width: 800px;
        margin-left: 10px;
    }

    .block {
        /* padding: 15px 15px 20px 15px; */
        border-radius: 8px;
        position: relative;
        background-color: #d2dce8;
        background-color: #dae0e8;
        margin: 5px;

        p {
            margin-bottom: 0;
            background-color: #5386c0;
            color: #fff;
            padding: 8px 10px;
            border-radius: 8px 8px 0px 0px;
        }

        p .tooltiptext {
          visibility: hidden;
          width: 200px;
          color:black;
          background: #fff;
          border: 1px solid black;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          top: 30px;
          left: 7px;
          /* 定位 */
          position: absolute;
          z-index: 1;
        }

        p:hover .tooltiptext {
          visibility: visible;
        }

        .count {
            font-size: 34px;
            font-weight: 700;
            text-align: center;
            line-height: 32px;
            color: #2a6fbd;
            letter-spacing: -0.5px;
            padding: 20px 0px;
        }

        .unit {
            position: absolute;
            right: 15px;
            bottom: 18px;
            color: #6d6c6c;
        }
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-4 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
}
</style>
