import axios from "@/util/axiosV2";

export default {
    login(data) {
        return axios.fetch({
            method: "post",
            url: "admins/login",
            data
        });
    },
    overview(data) {
        return axios.fetch({
            method: "post",
            url: "v1/dashboard/statistics",
            data
        });
    },
}
